import { NgModule } from '@angular/core';
import { FeedbackNavbarComponent } from '@feedback/shared/components/navbar/navbar.component';
import { FeedbackTopbarComponent } from '@feedback/shared/components/topbar/topbar.component';
import { SharedModule } from '@shared/shared.module';
import { NgXCableModule } from 'ngx-cable';

const components = [FeedbackNavbarComponent, FeedbackTopbarComponent];

@NgModule({
  imports: [SharedModule],
  exports: [SharedModule, NgXCableModule, components],
  declarations: [components]
})
export class FeedbackSharedModule {}
