import { Component, OnInit } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class FeedbackNavbarComponent implements OnInit {
  public links: {
    id: number;
    label: string;
    items: { label: string; icon: string; url: string }[];
  }[] = [
    {
      id: 0,
      label: 'NAVBAR.MENU',
      items: [
        {
          label: 'NAVBAR.OVERVIEW',
          url: '/dashboard',
          icon: 'lnr-chart-bars'
        },
        {
          label: 'NAVBAR.CANDIDATES',
          url: '/candidates',
          icon: 'lnr-clipboard-user'
        },
        {
          label: 'NAVBAR.PRE_SCREENINGS',
          url: '/pre-screenings',
          icon: 'lnr-thumbs-up'
        },
        {
          label: 'NAVBAR.EMPLOYERS',
          url: '/employers',
          icon: 'lnr-folder-user'
        },
        {
          label: 'NAVBAR.QUESTION_SETS',
          url: '/question-sets',
          icon: 'lnr-bubble-text'
        }
      ]
    }
  ];

  public isProduction: boolean = environment.IS_PRODUCTION;
  public params: any = {
    environment: environment.ENVIRONMENT
  };

  constructor() {}

  ngOnInit(): void {}

  public trackByFn(i: any) {
    return i.id;
  }
}
